<template>
  <div>
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-form>
              <b-row>
                <b-col cols="12" md="2">
                  <b-form-group
                    label="Hệ đào tạo"
                    label-for="trainingSystemId"
                  >
                    <v-select
                      v-model="filter.trainingSystemId"
                      :options="trainingSystems"
                      :reduce="option => option.value"
                      @input="onTrainingSystemsChange"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="3">
                  <b-form-group
                    label="Khóa học"
                    label-for="courseId"
                  >
                    <v-select
                      v-model="filter.courseId"
                      :options="courses"
                      :reduce="option => option.value"
                      @input="onCoursesChange"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="3">
                  <b-form-group
                    label="Học kỳ"
                    label-for="courseSemesterId"
                  >
                    <v-select
                      v-model="filter.courseSemesterId"
                      :options="courseSemesters"
                      :reduce="option => option.value"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="4">
                  <b-form-group
                    label="Khoa/Bộ môn"
                    label-for="departmentId"
                  >
                    <v-select
                      v-model="filter.departmentId"
                      :options="departments"
                      :reduce="option => option.value"
                      @input="onDepartmentChange"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" md="5">
                  <b-form-group
                    label="Môn học"
                    label-for="subjectId"
                  >
                    <v-select
                      v-model="filter.subjectId"
                      :options="subjects"
                      :reduce="option => option.value"
                      @input="onSubjectChange"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" md="5">
                  <b-form-group
                    label="Lớp độc lập"
                    label-for="creditClassId"
                  >
                    <v-select
                      v-model="filter.creditClassId"
                      :options="creditClasses"
                      :reduce="option => option.value"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="2" class="text-right" >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="btn-icon mt-2 mr-1"
                    variant="primary"
                    @click="onSearchMainData"
                  >
                    <feather-icon icon="SearchIcon" /> Lọc
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="success"
                    class="btn-icon mt-2"
                    size="sm"
                    title="In"
                    hidden="hidden"
                    @click="onPrintMainData(props.row)"
                  >
                    <feather-icon icon="PrinterIcon" /> In
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
            <b-row class="mt-2">
              <b-col cols="12">
                <vue-good-table
                  mode="remote"
                  class="my-table"
                  :columns="columns"
                  :rows="mainData"
                  :pagination-options="paginationOptions"
                  :total-rows="totalRows"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'status'">
                      {{ getStatusName(props.row.status) }}
                    </span>
                    <!-- Column: Common -->
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{filter.itemsPerPage * (filter.currentPage-1) +1}}
                          đến {{filter.itemsPerPage * (filter.currentPage)}} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                            v-model="filter.itemsPerPage"
                            :options="itemsPerPageOptions"
                            class="mx-1"
                            @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                            :value="1"
                            :total-rows="totalRows"
                            :per-page="filter.itemsPerPage"
                            class="mt-1 mb-0"
                            @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import {
  BButton, BCard, BCol, BContainer, BForm, BFormGroup, BFormSelect, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import { getUser } from '@/auth/utils'
import vSelect from 'vue-select'

export const Attr = Object.freeze({
  store: 'reportPointByCreditClass',
})
export default {
  name: 'PointByClassReport',
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
    BFormGroup,
    BForm,
    vSelect,
  },
  data() {
    return {
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        organizationId: getUser().orgId,
        trainingSystemId: '',
        courseId: '',
        courseSemesterId: '',
        departmentId: '',
        subjectId: '',
        creditClassId: '',
        isEligible: '',
        status: '',
        sort: '',
      },
      currentMainData: undefined,
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 100],
    }
  },
  computed: {
    ...mapGetters({
      mainData: 'creditClassStudent/dataLists',
      totalRows: 'creditClassStudent/totalRows',
      statuses: 'creditClassStudent/statuses',
      resourceName: `${Attr.store}/resourceName`,
      trainingSystems: 'dropdown/trainingSystems',
      courses: 'dropdown/courses',
      courseSemesters: 'dropdown/courseSemesters',
      departments: 'dropdown/departments',
      subjects: 'dropdown/subjects',
      creditClasses: 'dropdown/creditClasses',
    }),
    statusFilterDropdownItems() {
      return this.statuses.map(item => ({ value: item.value, text: item.label }))
    },
    columns() {
      return [
        {
          label: '#',
          field: 'rowNum',
          width: '30px',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        {
          label: 'Mã lớp ĐL',
          field: 'creditClassCode',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: `Tên ${this.resourceName.fullName}`,
          field: 'name',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: `Mã ${this.resourceName.fullName}`,
          field: 'code',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Điểm thi',
          field: 'score',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Điểm tổng kết',
          field: 'averagePoint',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Điểm chữ',
          field: 'alphabetPoint',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
            filterDropdownItems: this.statusFilterDropdownItems,
          },
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ]
    },
  },
  async created() {
    const cUser = getUser()
    const userOrgId = cUser.orgId ? cUser.orgId : 0
    this.filter.organizationId = userOrgId
    this.isLoading = true
    try {
      await Promise.all([
        this.getTrainingSystems({ organizationId: userOrgId }),
        this.getDepartments({ organizationId: userOrgId }),
      ])
      if (this.trainingSystems.length > 0) {
        this.filter.trainingSystemId = this.trainingSystems[0].value
        await this.getCourses(this.filter)
      }
      if (this.departments.length > 0) {
        this.filter.departmentId = this.departments[0].value
        await this.getSubjects(this.filter)
      }
      if (this.courses.length > 0) {
        this.filter.courseId = this.courses[0].value
        await this.getCourseSemesters(this.filter)
        if (this.courseSemesters.length > 0) {
          this.filter.courseSemesterId = this.courseSemesters[0].value
        }
      }
      if (this.subjects.length > 0) {
        this.filter.subjectId = this.subjects[0].value
        await this.getCreditClasses(this.filter)
      }
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getMainData: 'creditClassStudent/getData',
      printData: `${Attr.store}/printData`,
      getTrainingSystems: 'dropdown/getTrainingSystems',
      getCourses: 'dropdown/getCourses',
      getCourseSemesters: 'dropdown/getCourseSemesters',
      getDepartments: 'dropdown/getDepartments',
      getSubjects: 'dropdown/getSubjects',
      getCreditClasses: 'dropdown/getCreditClasses',
    }),
    async onTrainingSystemsChange(event) {
      this.filter.courseId = null
      await this.getCourses({ organizationId: getUser().orgId, trainingSystemId: event })
    },
    async onCoursesChange(event) {
      this.filter.courseSemesterId = null
      await this.getCourseSemesters({ organizationId: getUser().orgId, courseId: event })
    },
    async onDepartmentChange(event) {
      this.filter.subjectId = null
      await this.getSubjects({ organizationId: getUser().orgId, departmentId: event })
    },
    async onSubjectChange(event) {
      this.filter.creditClassId = null
      this.filter.subjectId = event
      await this.getCreditClasses(this.filter)
    },
    onPrintMainData(mainData) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = mainData
      this.currentMainData = rest
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    getStatusName(id) {
      return this.statuses.find(status => status.value === id).label
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getMainDataFromStore()
    },
    async getMainDataFromStore() {
      this.isLoading = true
      try {
        await this.getMainData(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getMainDataFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        const selectedColumn = this.columns.findIndex(e => e.field === field)

        this.updateParams({ sort: `${selectedColumn}_${type}` })
      }
      await this.getMainDataFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'code')) this.updateParams({ code: columnFilters.code })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      if (Object.hasOwn(columnFilters, 'isEligible')) this.updateParams({ status: columnFilters.isEligible })
      await this.getMainDataFromStore()
    },
    async onSucceed() {
      await this.getMainDataFromStore()
    },
    async onSearchMainData() {
      await this.getMainDataFromStore()
    },
  },
}
</script>
